import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  chakra,
  Divider,
  HStack,
  shouldForwardProp,
  Text,
  VStack,
} from "@chakra-ui/react";
import { isValidMotionProp, motion } from "framer-motion";
import { NavLink, useParams } from "react-router-dom";
import { routes } from "../routes";
import { useLocalStorage } from "@src/utils/custom-hooks/useLocalStorage";
import { useEffect } from "react";
import { SELECTED_APP } from "@src/consts/webshops/webshop";

const ChakraAside = chakra(motion.aside, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});

const variants = {
  open: {
    clipPath: `circle(2000px at 28px 0px)`,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 20,
      delay: 0.2,
    },
  },
  closed: {
    clipPath: "circle(16px at 28px 0px)",
    opacity: 0,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40,
      opacity: {
        delay: 0.2,
      },
    },
  },
};

export function Sidebar({ isOpen, onClose }) {
  // Handle Escape key
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape" && isOpen) {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  }, [isOpen, onClose]);

  return (
    <ChakraAside
      maxW="280px"
      w="full"
      bg="basics.black"
      pos="absolute"
      top="64px"
      bottom={0}
      left="0"
      zIndex="2"
      pl="32px"
      py="24px"
      pr="16px"
      initial={"closed"}
      animate={isOpen ? "open" : "closed"}
      variants={variants}
      aria-label="Main navigation"
      aria-hidden={!isOpen}
      aria-expanded={isOpen}
    >
      <VStack alignItems="flex-start" spacing={"16px"} as="nav">
        <SidebarGroups groups={[routes.webshop, routes.checkout]} />
        <SidebarLink route={routes.catalog} />
        <SidebarLink route={routes.developerSettings} />
        <Divider my="12px" />
        <SidebarLink route={routes.documentation} />
      </VStack>
    </ChakraAside>
  );
}

function SidebarGroups({ groups }) {
  return (
    <Accordion allowMultiple defaultIndex={[0, 1]} variant="sidebar">
      {groups.map((group) => (
        <AccordionItem key={group.name}>
          <AccordionButton>
            <HStack justifyContent={"space-between"} w="full">
              <Text color="neutrals.lightGrey" fontWeight={600} size="small">
                {group.label}
              </Text>
              <AccordionIcon />
            </HStack>
          </AccordionButton>
          <AccordionPanel>
            <VStack pt="16px" pb="8px" ml="16px" alignItems="flex-start" spacing={"16px"}>
              {group.children.map((child) => (
                <SidebarLink key={child.name} route={child} fontWeight={400} />
              ))}
            </VStack>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
}

function SidebarLink({ route, ...rest }) {
  const { id: appId } = useParams();
  const [selectedApp] = useLocalStorage(SELECTED_APP);
  const id = selectedApp || appId;
  const path = `${route.path}`.replace(":id", `${id}`);

  return (
    <NavLink to={path}>
      {({ isActive }) => {
        return (
          <Text
            size={"small"}
            color={isActive ? "brandPurple.400" : "basics.white"}
            _hover={{ color: "brandPurple.400" }}
            {...rest}
          >
            {route.label}
          </Text>
        );
      }}
    </NavLink>
  );
}
