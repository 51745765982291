import { Box, Button } from "@chakra-ui/react";
import { motion } from "framer-motion";

// Create motion components
const MotionBox = motion(Box);
const MotionButton = motion(Button);

// Variants for the background box
const backgroundVariants = {
  open: {
    opacity: 1,
    // calc of the button - the bottom padding
    y: 16,
  },
  closed: {
    opacity: 0,
    y: 0,
    transition: {
      duration: 0.08,
      delay: 0.4,
      stiffness: 20,
      damping: 20,
    },
  },
};

// Individual bar variants
const topBarVariants = {
  open: { rotate: 45, y: 5.5 },
  closed: { rotate: 0, y: 0 },
};

const middleBarVariants = {
  open: { opacity: 0 },
  closed: { opacity: 1 },
};

const bottomBarVariants = {
  open: { rotate: -45, y: -5.5 },
  closed: { rotate: 0, y: 0 },
};

export function MenuButton({ isOpen, onToggle }) {
  return (
    <Box pos="relative">
      <MotionBox
        w="32px"
        h="32px"
        bg="#000000"
        pos="absolute"
        borderTopRadius="10px"
        initial={"closed"}
        variants={backgroundVariants}
        animate={isOpen ? "open" : "closed"}
      />
      <MotionButton
        bg="#000000"
        px={0}
        minW="32px"
        h="32px"
        flexDir="column"
        gap="4px"
        pos="relative"
        aria-label="Toggle sidebar"
        onClick={() => {
          onToggle();
        }}
      >
        <MotionBox
          w="16px"
          h="1.5px"
          rounded="full"
          bg="brandSkyBlue.400"
          variants={topBarVariants}
          animate={isOpen ? "open" : "closed"}
        />
        <MotionBox
          w="16px"
          h="1.5px"
          rounded="full"
          bg="brandSkyBlue.400"
          variants={middleBarVariants}
          animate={isOpen ? "open" : "closed"}
        />
        <MotionBox
          w="16px"
          h="1.5px"
          rounded="full"
          bg="brandSkyBlue.400"
          variants={bottomBarVariants}
          animate={isOpen ? "open" : "closed"}
        />
      </MotionButton>
    </Box>
  );
}
