import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { getResponseStatus, getRequestStatus, getErrorStatus, setStatusDefaults } from "@utils/redux";

import WebshopsService, { ACCESS_TYPE } from "./webshopsApi";

import { USER_SUMMARY } from "../session/actions";

export const webshopsThunks = {
  accessRequest: createAsyncThunk("webshops/accessRequest", async (arg, { rejectWithValue }) => {
    try {
      const res = await WebshopsService.accessRequest();
      return res.data;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),
  getWebshops: createAsyncThunk("webshops/getWebshops", async (arg, { rejectWithValue }) => {
    try {
      const res = await WebshopsService.getWebshops();
      return res.data;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),
  createWebshops: createAsyncThunk("webshops/createWebshops", async (payload, { rejectWithValue }) => {
    try {
      const res = await WebshopsService.createWebshops(payload);
      return res.data;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),
  deleteWebshop: createAsyncThunk("webshops/deleteWebshop", async (id, { rejectWithValue }) => {
    try {
      await WebshopsService.deleteWebshop(id);
      return { id };
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),
  listAppStoreApps: createAsyncThunk("webshops/listAppStoreApps", async (payload, { rejectWithValue }) => {
    try {
      const res = await WebshopsService.getAppStoreApps(payload);
      return res.data;
    } catch (e) {
      return rejectWithValue({ error: e.response.data });
    }
  }),
};

export const webshopsSlice = createSlice({
  name: "webshops",
  initialState: {
    accessRequest: {
      requested: false,
      data: {},
      requestStatus: setStatusDefaults(),
    },
    getWebshops: {
      data: {
        meta: {
          googleAuthLink: "",
        },
        webshops: [],
      },
      requestStatus: setStatusDefaults(),
      initialLoadComplete: false,
    },
    createWebshops: {
      data: {},
      requestStatus: setStatusDefaults(),
    },
    deleteWebshop: {
      deletedIdsMap: {},
      requestStatus: setStatusDefaults(),
    },
    listAppStoreApps: {
      apps: [],
      requestStatus: setStatusDefaults(),
    },
    selectedWebshop: null,
    webshopsMap: {},
  },
  reducers: {
    setSelectedWebshop: (state, action) => {
      state.selectedWebshop = action.payload;
    },
    resetCreateWebshopRequestStatus: (state) => {
      state.createWebshops.requestStatus = setStatusDefaults();
    },
  },
  extraReducers: (builder) => {
    builder
      /*******************************************************************************
       * Access Request
       *******************************************************************************/
      .addCase(webshopsThunks.accessRequest.pending, (state) => {
        state.accessRequest.requestStatus = getRequestStatus();
      })
      .addCase(webshopsThunks.accessRequest.fulfilled, (state, action) => {
        const { payload = {} } = action;
        state.accessRequest.data = payload;
        state.accessRequest.requested = true;
        state.accessRequest.requestStatus = getResponseStatus();
      })
      .addCase(webshopsThunks.accessRequest.rejected, (state) => {
        state.accessRequest.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Get Webshops
       *******************************************************************************/
      .addCase(webshopsThunks.getWebshops.pending, (state) => {
        state.getWebshops.requestStatus = getRequestStatus();
      })
      .addCase(webshopsThunks.getWebshops.fulfilled, (state, action) => {
        const { payload = {} } = action;
        const { webshops = [] } = payload;
        state.getWebshops.data = payload;
        // Create a map of webshops for easy lookup
        webshops.forEach((webshop) => {
          state.webshopsMap[webshop.id] = webshop;
        });
        state.getWebshops.initialLoadComplete = true;
        state.getWebshops.requestStatus = getResponseStatus();
      })
      .addCase(webshopsThunks.getWebshops.rejected, (state) => {
        state.getWebshops.initialLoadComplete = true;
        state.getWebshops.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Create Webshop
       *******************************************************************************/
      .addCase(webshopsThunks.createWebshops.pending, (state) => {
        state.createWebshops.requestStatus = getRequestStatus();
      })
      .addCase(webshopsThunks.createWebshops.fulfilled, (state, action) => {
        const { payload = [] } = action;
        state.createWebshops.data = payload;
        // Add the new webshop to the list
        state.getWebshops.data.webshops = [payload, ...state.getWebshops.data.webshops];
        // Add the new webshop to the map
        state.webshopsMap[payload.id] = payload;
        state.createWebshops.requestStatus = getResponseStatus();
      })
      .addCase(webshopsThunks.createWebshops.rejected, (state) => {
        state.createWebshops.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * Delete Webshop
       *******************************************************************************/
      .addCase(webshopsThunks.deleteWebshop.pending, (state) => {
        state.deleteWebshop.requestStatus = getRequestStatus();
      })
      .addCase(webshopsThunks.deleteWebshop.fulfilled, (state, action) => {
        const { payload = {} } = action;
        const { id } = payload;
        state.deleteWebshop.deletedIdsMap[id] = true;
        // Remove the deleted webshop from the list
        state.getWebshops.data.webshops = state.getWebshops.data.webshops.filter((webshop) => {
          return webshop.id !== id;
        });
        // Remove the deleted webshop from the map
        state.webshopsMap[id] = null;
        state.deleteWebshop.requestStatus = getResponseStatus();
      })
      .addCase(webshopsThunks.deleteWebshop.rejected, (state) => {
        state.deleteWebshop.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * List AppStore Apps
       *******************************************************************************/
      .addCase(webshopsThunks.listAppStoreApps.pending, (state) => {
        state.listAppStoreApps.requestStatus = getRequestStatus();
      })
      .addCase(webshopsThunks.listAppStoreApps.fulfilled, (state, action) => {
        const { payload = [] } = action;
        state.listAppStoreApps.apps = payload;
        state.listAppStoreApps.requestStatus = getResponseStatus();
      })
      .addCase(webshopsThunks.listAppStoreApps.rejected, (state) => {
        state.listAppStoreApps.requestStatus = getErrorStatus();
      })
      /*******************************************************************************
       * User Summary response (to check webshop request status)
       *******************************************************************************/
      .addCase(USER_SUMMARY.RESPONSE, (state, action) => {
        try {
          const { response = {} } = action;
          const { company = {} } = response;
          const { accessRequests = [] } = company;
          const webshopAccessRequest = accessRequests.find((request) => request.type === ACCESS_TYPE);
          state.accessRequest.requested = Boolean(webshopAccessRequest);
        } catch (error) {
          state.accessRequest.requested = false;
        }
      });
  },
});

export const webshopsSelector = (state) => state.webshops.webshops;

export const appsSelector = (state) => state.webshops.webshops.getWebshops?.data?.webshops;
export const mappedAppsSelector = (state) => state.webshops.webshops.webshopsMap;

export const webshopsLoadingSelector = createSelector(
  webshopsSelector,
  (webshops) => webshops.getWebshops.requestStatus.pending,
);
export const webshopsListSelector = createSelector(
  webshopsSelector,
  (webshops) => webshops.getWebshops.data.webshops || [],
);
export const googleAuthLinkSelector = createSelector(
  webshopsSelector,
  (webshops) => webshops.getWebshops.data.meta.googleAuthLink,
);
export const listAppStoreAppsSelector = createSelector(webshopsSelector, (webshops) => webshops.listAppStoreApps);
export const webshopAccessRequestSelector = createSelector(
  webshopsSelector,
  (webshops) => webshops.accessRequest.requested,
);
export const webshopCreationErrorSelector = createSelector(
  webshopsSelector,
  (webshops) => webshops.createWebshops.requestStatus.completed && webshops.createWebshops.requestStatus.error,
);
export const selectedWebshopSelector = createSelector(webshopsSelector, (webshops) => webshops.selectedWebshop || {});

export const { setSelectedWebshop, resetCreateWebshopRequestStatus } = webshopsSlice.actions;

export default webshopsSlice.reducer;
