import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader, StyledPageContainer } from "@components/page";

import {
  developerThunks,
  getApiKeySelector,
  postApiKeySelector,
  maskApiKey,
} from "@redux/webshops/developer/developerSlice";

import { KeyGeneratorCard } from "@common/key-generator/key-modal-generator/KeyGeneratorCard";
import { BaseCard } from "../webshop/settings/components/BaseCard";

export const DeveloperSettingsPage = () => {
  return (
    <StyledPageContainer>
      <PageHeader header={"Developer Settings"} />
      <GlobalApiKey />
    </StyledPageContainer>
  );
};

export const GlobalApiKey = () => {
  const dispatch = useDispatch();
  const { data = {}, requestStatus = {} } = useSelector(getApiKeySelector);

  useEffect(() => {
    dispatch(developerThunks.getApiKey());
  }, [dispatch]);

  return (
    <BaseCard title={"Management API"}>
      <KeyGeneratorCard
        title={"API key"}
        subTitle={
          "Used to authenticate requests made to the API and ensure they are coming from an authorized client or service."
        }
        keyValue={data?.keyMask}
        requestStatus={requestStatus}
        generateKey={{
          thunk: developerThunks.generateApiKey,
          selector: postApiKeySelector,
          clean: maskApiKey,
        }}
      />
    </BaseCard>
  );
};
