import {
  Box,
  Button,
  HStack,
  Icon,
  Image,
  Skeleton,
  SkeletonCircle,
  Text,
  useDisclosure,
  useOutsideClick,
  Link,
} from "@chakra-ui/react";
import { MenuButton } from "./components/MenuButton";
import { Sidebar } from "./components/Sidebar";
import { Selector, SelectorContent, SelectorOption, SelectorTrigger, SelectorValue } from "@common/selector/Selector";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { routes } from "./routes";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appsSelector, mappedAppsSelector, webshopsLoadingSelector } from "@redux/webshops/webshopsSlice";
import { useLocalStorage } from "@src/utils/custom-hooks/useLocalStorage";
import { ReactComponent as ExternalLinkIcon } from "@assets/img/icons/common/ExternalLink.svg";
import { ReactComponent as PlusIcon } from "@assets/img/icons/common/plus.svg";
import { builderSelector, builderThunks } from "@redux/webshops/builder/builderSlice";
import { useMultiStepModal } from "@common/multi-step-modal/MultiStepModalContext";
import { SELECTED_APP } from "@src/consts/webshops/webshop";

export function Navbar() {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const sidebarRef = useRef();

  useOutsideClick({
    ref: sidebarRef,
    handler: onClose,
  });

  return (
    <Box ref={sidebarRef}>
      <Header isOpen={isOpen} onToggle={onToggle} />
      <Sidebar isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}

function Header({ isOpen, onToggle }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const isLoading = useSelector(webshopsLoadingSelector);
  const {
    publish: { requestStatus },
  } = useSelector(builderSelector);
  const { onOpen } = useMultiStepModal();

  const apps = useSelector(appsSelector);
  const mappedApps = useSelector(mappedAppsSelector);
  const [selectedApp] = useLocalStorage(SELECTED_APP);
  const appId = id || selectedApp;

  function handleOnAppChange(appId) {
    const newPath = location.pathname.replace(`/${id}/`, `/${appId}/`);

    return navigate(newPath);
  }

  function handlePublish() {
    dispatch(builderThunks.publishStorefront(id));
  }

  return (
    <HStack as={"header"} bg="#333333" py="16px" px="12px" pos={"fixed"} zIndex={3} top={0} left={0} right={0}>
      <MenuButton isOpen={isOpen} onToggle={onToggle} />

      <HStack gap="8px" mr="16px">
        <SkeletonCircle
          isLoaded={!isLoading}
          display={apps.length && !isLoading ? "block" : "none"}
          startColor="transparents.white"
          endColor="neutrals.mediumGrey"
        >
          {apps.length && (
            <Image objectFit={"cover"} borderRadius={"full"} w="32px" h="32px" src={mappedApps[appId]?.imageUrl} />
          )}
        </SkeletonCircle>
        <Skeleton
          isLoaded={!isLoading}
          borderRadius="10px"
          startColor="transparents.white"
          endColor="neutrals.mediumGrey"
        >
          {apps.length > 0 ? (
            <Selector defaultValue={appId} onChange={handleOnAppChange}>
              <SelectorTrigger
                bg="basics.black"
                h="32px"
                minWidth="160px"
                color="basics.white"
                iconColor="brandSkyBlue.400"
                _hover={{ bg: "basics.black" }}
              >
                <SelectorValue placeholder="Select a webshop" />
              </SelectorTrigger>
              <SelectorContent
                bg="basics.black"
                borderBottom="1px solid transparent"
                borderColor="neutrals.mediumGrey"
                maxH="152px"
                overflowY="scroll"
              >
                <Button
                  h="24px"
                  borderRadius="8px"
                  onClick={onOpen}
                  leftIcon={<Icon color="basics.white" as={PlusIcon} />}
                  w="100%"
                >
                  New project
                </Button>
                {apps.map((app) => (
                  <SelectorOption key={app.id} color="basics.white" value={app.id}>
                    {app.name}
                  </SelectorOption>
                ))}
              </SelectorContent>
            </Selector>
          ) : (
            <Button
              h="32px"
              bg="basics.black"
              color="basics.white"
              leftIcon={<Icon color="brandSkyBlue.400" as={PlusIcon} />}
              onClick={onOpen}
            >
              Create project
            </Button>
          )}
        </Skeleton>
      </HStack>
      <Subroutes />
      <HStack ml="auto" gap="16px">
        <Link isExternal fontSize={"sm"} fontWeight={500} href="https://help.sanlo.ai">
          Help
          <Icon as={ExternalLinkIcon} ml="8px" w="16px" h="16px" verticalAlign={"text-top"} />
        </Link>
        <Button border="1px solid" borderColor="#AAAAAA" h="32px" borderRadius={"4px"}>
          Preview
        </Button>
        <Button
          h="32px"
          borderRadius={"4px"}
          color={"basics.black"}
          bg="brandGreen.400"
          _hover={{ bg: "brandGreen.400" }}
          isLoading={requestStatus.pending}
          onClick={handlePublish}
        >
          Publish
        </Button>
      </HStack>
    </HStack>
  );
}

function Subroutes() {
  const { pathname } = useLocation();
  const isWebshopRoute = pathname?.includes("webshop") && !pathname?.includes("catalog");
  const isCheckoutRoute = pathname?.includes("checkout");

  if (!isWebshopRoute && !isCheckoutRoute) return null;

  return (
    <HStack ml="16px" gap={"16px"}>
      {isWebshopRoute
        ? routes.webshop.children.map((subroute) => <SubrouteLink key={subroute.name} route={subroute} />)
        : routes.checkout.children.map((subroute) => <SubrouteLink key={subroute.name} route={subroute} />)}
    </HStack>
  );
}

function SubrouteLink({ route }) {
  const { id } = useParams();
  const path = `${route.path}`.replace(":id", `${id}`);

  return (
    <NavLink to={path}>
      {({ isActive }) => (
        <Text
          size={"small"}
          color={isActive ? "brandPurple.400" : "basics.white"}
          _hover={{ color: "brandPurple.400" }}
          fontWeight={500}
        >
          {route.label}
        </Text>
      )}
    </NavLink>
  );
}
