import { useCallback, useSyncExternalStore } from "react";

export function useLocalStorage(key, defaultValue) {
  if (!key) {
    throw new Error("You must pass a key to useLocalStorage");
  }

  // This immediate set helps appease the Cypress gods
  if (window?.Cypress && defaultValue)
    window.localStorage.setItem(key, typeof defaultValue === "object" ? JSON.stringify(defaultValue) : defaultValue);

  function getSnapshot() {
    const item = window.localStorage.getItem(key);
    if (!item) return defaultValue;

    return item;
  }

  function subscribe(callback) {
    window.addEventListener("storage", callback);
    return () => window.removeEventListener("storage", callback);
  }

  const setValue = useCallback(
    (value) => {
      try {
        // Allow value to be a function to match useState API
        if (!value) {
          window.localStorage.removeItem(key);
          return;
        }
        const valueToStore = typeof value === "object" ? JSON.stringify(value) : value;
        // Save to localStorage
        window.localStorage.setItem(key, valueToStore);
      } catch (error) {
        // Catch errors saving value to localStorage
        console.error("Error saving value to localStorage:", error);
      }
    },
    [key],
  );

  const value = useSyncExternalStore(subscribe, getSnapshot);
  let parsedValue;

  try {
    parsedValue = JSON.parse(value);
  } catch (e) {
    parsedValue = value;
  }

  return [parsedValue, setValue];
}
