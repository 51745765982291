import React, { useState, useRef, createContext, useContext, useEffect } from "react";
import { Box, Button, VStack, Text, useDisclosure, useOutsideClick } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

const MotionBox = motion(Box);

const SelectContext = createContext();

function useSelectorContext() {
  const context = useContext(SelectContext);
  if (!context) {
    throw new Error("Selector subcomponents cannot be rendered outside the Selector component");
  }
  return context;
}

export const Selector = ({ defaultValue, onChange, children, ...rest }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [optionsMap, setOptionsMap] = useState(new Map());
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const selectRef = useRef();

  useOutsideClick({
    ref: selectRef,
    handler: onClose,
  });
  // Get values and labels from SelectorOption children
  useEffect(() => {
    const newOptionsMap = new Map();
    React.Children.forEach(children, (child) => {
      if (child?.type?.displayName === "SelectorContent") {
        React.Children.forEach(child.props.children, (option) => {
          if (option?.type?.displayName === "SelectorOption" && option.props.value) {
            newOptionsMap.set(option.props.value, option.props.children);
          }
        });
      }
    });
    setOptionsMap(newOptionsMap);
  }, [children]);

  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <SelectContext.Provider
      value={{
        selectedValue,
        setSelectedValue,
        isOpen,
        onOpen,
        onClose,
        onChange,
        onToggle,
        optionsMap,
        setOptionsMap,
      }}
    >
      <Box position="relative" {...rest} ref={selectRef}>
        {children}
      </Box>
    </SelectContext.Provider>
  );
};

export function SelectorValue({ placeholder }) {
  const { selectedValue, optionsMap } = useSelectorContext();
  const displayValue = selectedValue ? optionsMap.get(selectedValue) : placeholder;

  return displayValue;
}

export function SelectorTrigger({ children, iconColor, ...rest }) {
  const { isOpen, onToggle } = useSelectorContext();

  return (
    <Button
      onClick={onToggle}
      rightIcon={
        isOpen ? (
          <ChevronUpIcon color={iconColor} w="16px" h="16px" />
        ) : (
          <ChevronDownIcon color={iconColor} w="16px" h="16px" />
        )
      }
      width="100%"
      aria-haspopup="listbox"
      aria-expanded={isOpen}
      color="textSubtext.400"
      boxShadow={"none"}
      justifyContent={"space-between"}
      {...rest}
    >
      {children}
    </Button>
  );
}

export function SelectorContent({ children, ...rest }) {
  const { isOpen, selectedValue } = useSelectorContext();

  return (
    <AnimatePresence>
      {isOpen && (
        <MotionBox
          position="absolute"
          width="100%"
          bg="dark.600"
          boxShadow="md"
          mt="2"
          zIndex="1"
          borderRadius="10px"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          role="datalist"
          aria-activedescendant={selectedValue}
          p={"8px"}
          {...rest}
        >
          <VStack gap={"4px"} align="start">
            {children}
          </VStack>
        </MotionBox>
      )}
    </AnimatePresence>
  );
}
SelectorContent.displayName = "SelectorContent";

export function SelectorOption({ children, value, ...rest }) {
  const { selectedValue, setSelectedValue, onChange, onClose } = useSelectorContext();

  return (
    <Text
      width="100%"
      p="4px"
      pl="8px"
      cursor="pointer"
      _hover={{ bg: "transparents.eBlue" }}
      bg={"transparent"}
      borderRadius={"8px"}
      onClick={() => {
        setSelectedValue(value);
        onChange?.(value);
        onClose();
      }}
      role="option"
      aria-selected={selectedValue && selectedValue === value}
      id={`option-${value}`}
      color={"textSubtext.400"}
      {...rest}
    >
      {children}
    </Text>
  );
}
SelectorOption.displayName = "SelectorOption";
