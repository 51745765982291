import { Button, Divider, Heading, Icon, Text, VStack } from "@chakra-ui/react";
import { useMultiStepModal } from "@common/multi-step-modal/MultiStepModalContext";
import { ReactComponent as Plus } from "src/assets/img/icons/common/plus.svg";

export function ProjectsEmptyState() {
  const { onOpen } = useMultiStepModal();

  return (
    <VStack px="32px" pt="48px" gap="32px" alignItems="flex-start">
      <VStack gap="16px" alignItems="flex-start">
        <Heading size={"headline"} fontWeight={600}>
          Projects
        </Heading>
        <Text>
          Lets create your first project! We’ll walk you through each step. You’ll need this, this and this information
          to create your first project. Visit our documentation for more help.
        </Text>
      </VStack>
      <Divider my="0" />
      <Button
        onClick={onOpen}
        leftIcon={<Icon color="basics.black" as={Plus} />}
        bg="brandGreen.400"
        color="basics.black"
        _hover={{ bg: "brandGreen.400" }}
      >
        New project
      </Button>
    </VStack>
  );
}
