import React, { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";

import {
  Box,
  HStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  PopoverTrigger,
  PopoverContent,
  Popover,
  PopoverCloseButton,
  Input,
  Icon,
  Button,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

import { hexFormatter, isValidHex } from "./utils";

const DEFAULT_COLOR = "#FFFFFF";

export const ColorPicker = ({
  target = "",
  title = "Color",
  // Tab of quick color options
  presets = [],
  // Tab for custom color picker
  custom = true,
  onColorChange = () => {},
  defaultColor = DEFAULT_COLOR,
  ...rest
}) => {
  // Needed for the tooltip/modal link
  const id = `${target}_color_picker_tooltip_${title}`;
  const showPresets = presets && presets.length > 0;
  const [currentColor, setCurrentColor] = useState(defaultColor);

  useEffect(() => {
    setCurrentColor(defaultColor);
  }, [defaultColor]);

  return (
    <Popover placement="left-start">
      {({ isOpen }) => (
        <Box>
          <PopoverTrigger>
            <Button
              id={id}
              display={"flex"}
              w={"100%"}
              borderRadius={0}
              h="48px"
              justifyContent="space-between"
              cursor="pointer"
              p="12px"
              bg={isOpen ? "rgba(33, 33, 38, 0.78)" : "initial"}
              _hover={{ background: "transparents.dark" }}
              variant={"unstyled"}
              color="textDefault.400"
              {...rest}
            >
              {title}
              {!defaultColor ? (
                <Box width="24px" height="24px" pl="4px">
                  <Icon color="brandSkyBlue.400" as={AddIcon} w="16px" h="16px" />
                </Box>
              ) : (
                <Box
                  width="24px"
                  height="24px"
                  borderRadius="8px"
                  backgroundColor={currentColor}
                  border="1px solid rgba(250, 250, 250, 0.20)"
                  aria-label={`color ${currentColor}`}
                />
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent maxW={"280px"}>
            {/* Close Button */}
            <PopoverCloseButton zIndex={1} />

            {/* Tabs */}
            <Tabs>
              <TabList>
                {showPresets && <Tab>Preset</Tab>}
                {custom && <Tab>Custom</Tab>}
              </TabList>
              <TabPanels>
                {showPresets && (
                  <TabPanel>
                    <HStack>
                      {presets.map((presetColor, index) => (
                        <Box
                          key={`preset_color_${index}_${presetColor}`}
                          width="40px"
                          height="40px"
                          borderRadius="10px"
                          backgroundColor={currentColor}
                          border={presetColor === currentColor ? "5px solid rgba(255, 255, 255, 0.70)" : "none"}
                          cursor="pointer"
                          onClick={() => {
                            setCurrentColor(presetColor);
                            onColorChange(presetColor);
                          }}
                        />
                      ))}
                    </HStack>
                  </TabPanel>
                )}
                {custom && (
                  <TabPanel>
                    <HexColorPicker
                      style={{ width: "100%" }}
                      color={currentColor}
                      onChange={setCurrentColor}
                      onMouseUp={() => onColorChange(currentColor)}
                    />
                    <Input
                      mt="16px"
                      value={currentColor}
                      aria-label={`color ${currentColor}`}
                      onChange={(e) => {
                        const value = e.currentTarget.value;
                        setCurrentColor(hexFormatter(value));
                        if (isValidHex(value)) {
                          onColorChange(hexFormatter(value));
                        }
                        return hexFormatter(value);
                      }}
                    />
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
          </PopoverContent>
        </Box>
      )}
    </Popover>
  );
};
