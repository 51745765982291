import { Box, Spinner, VStack } from "@chakra-ui/react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Navbar } from "./components/navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { appsSelector, webshopsSelector, webshopsThunks } from "@redux/webshops/webshopsSlice";
import { useLocalStorage } from "@src/utils/custom-hooks/useLocalStorage";
import { useMultiStepModal } from "@common/multi-step-modal/MultiStepModalContext";
import { StoreSelection } from "@pages/webshops/new-project/new-project-steps/StoreSelectionStep";
import { ProjectName } from "@pages/webshops/new-project/new-project-steps/ProjectNameStep";
import { ProjectsEmptyState } from "./components/project-empty-state/ProjectsEmptyState";
import { SELECTED_APP } from "@src/consts/webshops/webshop";
import { showToast } from "@src/utils/redux";

const steps = [<StoreSelection key="StoreSelection" />, <ProjectName key="ProjectName" />];

export function Layout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { state } = useLocation();
  const { id } = useParams();
  const [selectedApp, setSelectedApp] = useLocalStorage(SELECTED_APP);
  const appsRetrieved = useRef(false);
  const { setSteps } = useMultiStepModal();
  const apps = useSelector(appsSelector);
  const {
    getWebshops: { requestStatus },
  } = useSelector(webshopsSelector);
  const isLoading = requestStatus.pending;
  const idAppExists = apps?.find((app) => app.id === id);
  const creationHasFailed = state?.creationHasFailed;

  useEffect(() => {
    setSteps(steps);
  }, [setSteps]);

  useEffect(() => {
    async function getApps() {
      const apps = await dispatch(webshopsThunks.getWebshops()).unwrap();
      if (!apps.webshops?.length) {
        localStorage.removeItem(SELECTED_APP);
        return;
      }
      const firstAppId = apps?.webshops[0]?.id;
      const selectedAppExists = apps?.webshops?.find((app) => app.id === selectedApp);
      const idParamAppExists = apps?.webshops?.find((app) => app.id === id);
      const shouldSetFirstApp = !selectedApp && !selectedAppExists && !idParamAppExists;

      if (shouldSetFirstApp) {
        return setSelectedApp(firstAppId);
      }
      return setSelectedApp(id);
    }

    if (!appsRetrieved.current) {
      getApps();
      appsRetrieved.current = true;
    }

    if (idAppExists && id !== selectedApp) {
      setSelectedApp(id);
    }
  }, [dispatch, selectedApp, setSelectedApp, navigate, pathname, id, idAppExists]);

  useEffect(() => {
    if (pathname.endsWith("/new")) {
      return;
    }

    const getNewPath = (appId) =>
      pathname.endsWith("/projects") ? `${appId}/webshop/builder` : pathname.replace(`/${id}/`, `/${appId}/`);

    return navigate(getNewPath(selectedApp));
  }, [id, navigate, pathname, selectedApp]);

  useEffect(() => {
    if (creationHasFailed) {
      showToast("Something went wrong while creating your project. Please try again.", "error");
    }
  }, [creationHasFailed]);

  const component = apps.length ? <Outlet /> : <ProjectsEmptyState />;

  if (isLoading) {
    return (
      <VStack w="100%" h="100%" justifyContent="center" alignItems="center">
        <Spinner size="xl" label="Loading..." />
      </VStack>
    );
  }

  return (
    <>
      <Navbar />
      <Box h="100%" maxH="calc(100% - 64px)" overflow={"scroll"} isolation={"isolate"} mt="64px">
        {component}
      </Box>
    </>
  );
}
