/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";

import actions from "./redux/actions";

import { CustomLoader } from "@components/common/loaders";
import StyledLogoutModal from "./components/common/modals/StyledLogoutModal";
import { MainSidebar } from "@components/sidebar/SideBar";
import { TitleRegularPlus } from "./components/styled";

import ErrorBoundary from "@components/ErrorBoundary";
import { PrePlaidModal } from "./components/common/modals/pre-plaid-modal";
import { Flex, chakra, shouldForwardProp } from "@chakra-ui/react";

import { useLogout } from "@utils/custom-hooks/useAuth";
import { trackPageViewEvent } from "@utils/gtm";
import { getUserName } from "@utils/users";
import { hideSidebar } from "@utils/general";

import { isValidMotionProp, motion } from "framer-motion";
import { MultiStepModalProvider } from "@common/multi-step-modal/MultiStepModalContext";

const ChakraBox = chakra(motion.main, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});

const Main = ({ user = {}, ...props }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const appLogout = useLogout();

  const [sideNavOpen, setSideNavOpen] = useState(true);
  const [sideNavHidden, setSideNavHidden] = useState(false);

  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const integrations = useSelector((state) => state.integrations);
  const isAppsFlyerPullingData = integrations.appsflyer.connectionStatus.isPullingData;
  const isAdjustPullingData = integrations.adjust.connectionStatus.isPullingData;
  const isAppStorePullingData = integrations.appstore.connectionStatus.isPullingData;
  const isGooglePlayPullingData = integrations.googleplay.connectionStatus.isPullingData;
  const isAdvanceConnected =
    integrations.advances.connectionStatus.isConnected || integrations.advances.connectionStatus.isPullingData;

  const offers = useSelector((state) => state.offers);
  const advancesSummaryData = integrations.advances.data.summary;

  const toggleSidenav = (value) => {
    if (typeof value === "boolean") {
      setSideNavOpen(value);
    } else {
      setSideNavOpen(!sideNavOpen);
    }
  };

  const handleLogout = () => {
    appLogout();
    setLogoutModalOpen(true);
  };

  useEffect(() => {
    props.featureFlagsRequest();
    props.getAdvancesSummary();

    dispatch(actions.offers.getCapitalOffers.request());
    dispatch(actions.integrations.adjust.getDraftCredentials.request());
    dispatch(actions.integrations.googleplay.retrieveServiceAccount.request());

    // Add beforeunload event listener to send a page view end event
    const handleBeforeUnload = () => {
      trackPageViewEvent({
        onunload: true,
      });
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSideNavHidden(hideSidebar(location));
    trackPageViewEvent({
      pathname: location.pathname,
    });
  }, [location]);

  useEffect(() => {
    // TODO: Redirect branded/partner users to growth capital page
    // if they have no pending offers (service work required)
  }, [advancesSummaryData]);

  useEffect(() => {
    if (isAdvanceConnected) {
      props.getAdvancesRequest();
    }
    // eslint-disable-next-line
  }, [isAdvanceConnected]);

  useEffect(() => {
    if (isAppsFlyerPullingData) {
      setTimeout(() => {
        dispatch(actions.integrations.appsflyer.getUASpend.request());
      }, 120000);
    }
  }, [isAppsFlyerPullingData, dispatch]);

  useEffect(() => {
    if (isAdjustPullingData) {
      setTimeout(() => {
        dispatch(actions.integrations.adjust.getUASpend.request());
      }, 120000);
    }
  }, [isAdjustPullingData, dispatch]);

  useEffect(() => {
    if (isAppStorePullingData) {
      setTimeout(() => {
        dispatch(actions.earnings.appstoreGetEarnings.request());
      }, 120000);
    }
  }, [isAppStorePullingData, dispatch]);

  useEffect(() => {
    if (isGooglePlayPullingData) {
      setTimeout(() => {
        dispatch(actions.earnings.googleplayGetEarnings.request());
      }, 120000);
    }
  }, [isGooglePlayPullingData, dispatch]);

  return (
    <ErrorBoundary name="main-container" showDialog>
      <MultiStepModalProvider>
        <Flex id="app" h={"full"} w={"full"}>
          {/* <MainSidebar
            userName={getUserName(user)}
            location={location}
            flags={Object.assign({}, useFlags())}
            sidenavOpen={sideNavOpen}
            offers={offers.capitalOffers}
            onLogout={handleLogout}
            toggleSidenav={toggleSidenav}
            hidden={sideNavHidden}
          /> */}

          <ChakraBox
            h={"full"}
            flex={"1"}
            // animate={{
            //   marginLeft: sideNavOpen && !sideNavHidden ? "270px" : sideNavHidden ? 0 : "60px",
            // }}
          >
            <Outlet />
          </ChakraBox>

          {sideNavOpen && <div className="backdrop d-xl-none" onClick={toggleSidenav} />}

          <StyledLogoutModal className="modal-dialog-centered" isOpen={logoutModalOpen}>
            <div className="logout-modal-content">
              <TitleRegularPlus>Logging Out...</TitleRegularPlus>
              <CustomLoader color={"gray"} />
            </div>
          </StyledLogoutModal>
        </Flex>
        <PrePlaidModal />
      </MultiStepModalProvider>
    </ErrorBoundary>
  );
};

export default Main;
