import { GridItem } from "@chakra-ui/react";
import { Builder } from "./components/Builder";
import { BuilderProperties } from "./components/Properties";
import { useDispatch } from "react-redux";
import { checkoutBuilderThunks } from "@redux/webshops/checkout-builder/checkoutSlice";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

export function CheckoutBuilder() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [template, setTemplate] = useState();

  const logo = template?.files?.[0]?.url ?? "";

  const methods = useForm({
    values: {
      primary: template?.colorPalette?.primary?.color,
      secondary: template?.colorPalette?.secondary?.color,
      paymentSectionBgColor: template?.paymentSectionBgColor,
      summarySectionBgColor: template?.summarySectionBgColor,
    },
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  useEffect(() => {
    async function getCheckoutTemplate() {
      const checkoutTemplate = await dispatch(checkoutBuilderThunks.getCheckoutTemplate({ webshopId: id })).unwrap();
      setTemplate(checkoutTemplate);
    }
    getCheckoutTemplate();
  }, [dispatch, id]);

  return (
    <FormProvider {...methods}>
      <GridItem bg="#777777" alignContent={"center"}>
        <Builder logo={logo} />
      </GridItem>
      <GridItem bg="#18181A" borderLeft={"1px solid #404247"}>
        <BuilderProperties template={template} />
      </GridItem>
    </FormProvider>
  );
}
