export const routes = {
  catalog: {
    name: "catalog",
    label: "Catalog",
    path: ":id/webshop/catalog",
  },
  webshop: {
    name: "webshop",
    label: "Webshop",
    children: [
      {
        name: "webshop-builder",
        label: "Builder",
        path: ":id/webshop/builder",
      },
      {
        name: "webshop-settings",
        label: "Settings",
        path: ":id/webshop/settings",
      },
    ],
  },
  checkout: {
    name: "checkout",
    label: "Checkout",
    children: [
      {
        name: "checkout-builder",
        label: "Builder",
        path: ":id/checkout/builder",
      },
      {
        name: "checkout-settings",
        label: "Settings",
        path: ":id/checkout/settings",
      },
    ],
  },
  developerSettings: {
    name: "developer-settings",
    label: "Developer Settings",
    path: "/projects/developer-settings",
  },
  documentation: {
    name: "documentation",
    label: "Documentation",
    path: "documentation",
  },
};
