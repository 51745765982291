import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { associationsThunks } from "@redux/webshops/associations/associationsSlice";
import { builderThunks } from "@redux/webshops/builder/builderSlice";
import { catalogThunks } from "@redux/webshops/catalog/catalogSlice";
import { startPublishListener, stopPublishListener } from "@redux/webshops/catalog/middleware";
import { useFlags } from "launchdarkly-react-client-sdk";
import { setSelectedWebshop, webshopsSelector } from "@redux/webshops/webshopsSlice";

export function WebshopLayout() {
  const { offerSeries } = useFlags();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { webshopsMap } = useSelector(webshopsSelector);
  const selectedWebshop = webshopsMap[id];

  useEffect(() => {
    if (id) {
      dispatch(setSelectedWebshop(selectedWebshop));
      dispatch(builderThunks.getStorefront(id));
      // The catalog is needed to drive the drop-downs for item selection
      // right now and to set the title if its not set
      if (offerSeries) {
        startPublishListener();
        dispatch(catalogThunks.getCatalogFromWebshopV2(id));
        dispatch(catalogThunks.unassignedSeriesItems(id));
      } else {
        dispatch(catalogThunks.getCatalogFromWebshop(id));
      }
      // Associations are needed for category
      dispatch(associationsThunks.getAssociations(id));

      return () => {
        stopPublishListener();
      };
    }
  }, [id, offerSeries, dispatch, selectedWebshop]);

  return <Outlet />;
}
