import React, { useEffect } from "react";
import { NavLink, ScrollRestoration, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Heading, Link, Text, VStack } from "@chakra-ui/react";

import { webshopsSelector } from "@redux/webshops/webshopsSlice";
import { settingsThunks, settingsSelector } from "@redux/webshops/webshop/settings/settingsSlice";
import { integrationsThunks } from "@redux/webshops/webshop/settings/integration/integrationsSlice";

import {
  SiteDetails,
  SiteImages,
  Payments,
  CustomDomain,
  BaseDomain,
  Verification,
  ClientToken,
  EmailReceipt,
  AppsFlyer,
} from "./components";
import { Spinner } from "@components/common/loaders";
import { ProjectId } from "./components/ProjectId";
import { PlayerAuthentication } from "./components/player-authentication/PlayerAuthentication";
import ErrorBoundary from "@components/ErrorBoundary";

export const Settings = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { clientSdkTokenEnable = false, appsFlyerWebshop = false } = useFlags();

  const { webshopsMap } = useSelector(webshopsSelector);
  const selectedWebshop = webshopsMap[id];
  const { getSettingsStatus } = useSelector(settingsSelector);
  const isLoading = getSettingsStatus.pending;

  useEffect(() => {
    if (id) {
      dispatch(settingsThunks.getSettings({ webshopId: id }));
      if (appsFlyerWebshop) dispatch(integrationsThunks.getIntegrations({ webshopId: id }));
    }
  }, [appsFlyerWebshop, dispatch, id]);

  return (
    <VStack alignItems={"stretch"} px="64px">
      <ScrollRestoration />
      <VStack alignItems={"stretch"} gap={"16px"} mb="24px" py="16px">
        <Heading size="headline" fontWeight={"600"}>
          Project Settings
        </Heading>
        <Text size="small" color="textSubtext.400">
          The settings below apply only to this webshop project. Global settings that apply to all projects can be found
          here on the{" "}
          <Link
            color={"textWhite.400"}
            fontWeight={600}
            textDecoration={"underline"}
            _hover={{ color: "textWhite.400" }}
            as={NavLink}
            to="/webshops/projects"
          >
            all-projects home page
          </Link>
        </Text>
      </VStack>
      <ErrorBoundary name="webshop-settings-page" showAltUI={isLoading || !selectedWebshop} altUI={<Spinner />}>
        <VStack alignItems={"center"} flex={"1 0 0"} gap={"24px"} mb="24px">
          <ProjectId />
          <PlayerAuthentication />
          <CustomDomain />
          <BaseDomain />
          <SiteDetails />
          <SiteImages />
          <Payments />
          <EmailReceipt />
          <Verification />
          {clientSdkTokenEnable && <ClientToken />}
          {appsFlyerWebshop && <AppsFlyer />}
        </VStack>
      </ErrorBoundary>
    </VStack>
  );
};
