import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Input,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { InstructionItem, InstructionsList } from "@common/guided-instructions/GuidedInstructions";
import { GuideMedia } from "@components/integrations/connection-guide";
import symbols from "@src/utils/symbols";
import appleInstruction1 from "../apple-instructions-media/apple-instruction-1.jpg";
import appleInstruction2 from "../apple-instructions-media/apple-instruction-2.png";
import appleInstruction3 from "../apple-instructions-media/apple-instruction-3.png";
import appleInstruction4 from "../apple-instructions-media/apple-instruction-4.png";
import { useLayoutEffect } from "react";
import { useMultiStepModal } from "@common/multi-step-modal/MultiStepModalContext";
import { useFormContext } from "react-hook-form";

export function AppStoreInstructions({ handleNextStep }) {
  const { register, watch, formState } = useFormContext();
  const { setShowProgress, setShowStepsLeft } = useMultiStepModal();

  const issuerId = watch("issuerId");
  const keyId = watch("keyId");
  const isValid = issuerId?.length > 0 && keyId?.length > 0;

  useLayoutEffect(() => {
    setShowProgress(true);
    setShowStepsLeft(true);
  }, [setShowProgress, setShowStepsLeft]);

  function handleLastStep() {
    handleNextStep();
  }

  return (
    <VStack mt="40px" gap="104px">
      <Heading size="large" fontWeight={600}>
        Connect via App Store
      </Heading>
      <HStack gap="24px" alignItems={"start"}>
        <Box maxW="420px">
          <InstructionsList>
            <InstructionItem>
              <Text size={"regular"} fontWeight={500} color={"textWhite.400"}>
                Log into your{" "}
                <Link
                  textDecoration={"underline"}
                  color={"brandRed.400"}
                  _hover={{ color: "brandRed.400" }}
                  href="https://appstoreconnect.apple.com/login"
                  isExternal
                >
                  App Store connect
                </Link>{" "}
                account
              </Text>
              <Text size="small">
                1.1 Select{" "}
                <Text as="span" color={"textWhite.400"} fontWeight={500}>
                  Users and Access
                </Text>
                .
              </Text>
              <Text size="small">
                1.2 Navigate to the{" "}
                <Text as="span" color={"textWhite.400"} fontWeight={500}>
                  Integrations
                </Text>{" "}
                tab.
              </Text>
              <Text size="small">
                1.3 Click the{""}
                <Text as="span" color={"textWhite.400"} fontWeight={500}>
                  “+”
                </Text>{" "}
                button to generate a new API key.
              </Text>
              <Text size="small">
                1.4 Enter a name, select access, and then click{" "}
                <Text as="span" color={"textWhite.400"} fontWeight={500}>
                  “generate”
                </Text>
                .
              </Text>
            </InstructionItem>
            <InstructionItem>
              <Text size={"regular"} fontWeight={500} color={"textWhite.400"}>
                Provide App and Issuer ID. {symbols.pointDown}
              </Text>
              <Text size="small">
                2.1 Copy{" "}
                <Text as="span" color={"textWhite.400"} fontWeight={500}>
                  “Issuer ID”
                </Text>
                to clipboard and paste below.
              </Text>
              <Text size="small">
                2.2 Copy new{" "}
                <Text as="span" color={"textWhite.400"} fontWeight={500}>
                  “Key ID”
                </Text>{" "}
                to clipboard and paste below.
              </Text>
            </InstructionItem>
          </InstructionsList>
          <Divider />
          <FormControl>
            <FormLabel fontSize={"sm"}>
              Issuer ID
              <Input {...register("issuerId", { required: true })} mt="8px" />
            </FormLabel>
            {formState.errors?.issuerId && <FormHelperText color="brandRed.400">Issuer ID is required</FormHelperText>}
          </FormControl>
          <FormControl mt="24px">
            <FormLabel fontSize={"sm"}>
              Key ID
              <Input {...register("keyId", { required: true })} mt="8px" />
            </FormLabel>
            {formState.errors?.keyId && <FormHelperText color="brandRed.400">Key ID is required</FormHelperText>}
          </FormControl>
          <Button isDisabled={!isValid} onClick={handleLastStep} variant={"primary"} mt="24px" w="full">
            Continue
          </Button>
        </Box>
        <Box maxW={"512px"} p="12px" rounded={"12px"} bg="dark.400" h="fit-content">
          <GuideMedia mediaSlider={[appleInstruction1, appleInstruction2, appleInstruction3, appleInstruction4]} />
        </Box>
      </HStack>
    </VStack>
  );
}
